/* eslint-env browser */
import React from "react";
import {Helmet} from "react-helmet-async";
import {useLocation} from "react-router-dom";
import keyBy from "lodash/keyBy";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useFilter, useInterval, useSort} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import SearchContext from "../../components/contexts/SearchContext.jsx";
import useJwt from "../../components/contexts/useJwt.jsx";
import Alert from "../../components/primitives/Alert.jsx";
import NoResults from "../../components/primitives/NoResults.jsx";
import Pagination from "../../components/primitives/Pagination.jsx";
import TableLoading from "../../components/primitives/TableLoading.jsx";
import Page404 from "../Page404.jsx";

//---------------------------------------------------------------------------
// Lazy-load page-specific components
//---------------------------------------------------------------------------
const InsuranceTypesForm = React.lazy(
  () => import(/* webpackPrefetch: true */ "../../dialogs/InsuranceTypesForm.jsx")
);
const InsuranceTypesHeader = React.lazy(
  () => import(/* webpackPrefetch: true */ "./InsuranceTypesHeader.jsx")
);
const InsuranceTypesRow = React.lazy(() => import(/* webpackPrefetch: true */ "./InsuranceTypesRow.jsx"));

// Unless we can get socket.io or long polling working, fetching the data
// every 15 seconds should keep things from getting "stale"
const DATA_REFRESH_INTERVAL_MS = 15000;

//---------------------------------------------------------------------------
// Export search configuration for use in navbar
//---------------------------------------------------------------------------
const searchFields = {
  name: "name",
};
const searchHelper = [
  {label: "Has the words", keyword: "+", variant: "global"},
  {label: "Doesn't have", keyword: "-", variant: "global"},
];

const defaultSort = {
  field: "name",
  reverse: false,
};
const pageSize = 50;

//--------------------------------------------------------------------------
export function allowInsuranceTypes(isInAnyRole, inboxAccess) {
  if (arguments.length !== 2) {
    throw new Error("Wrong number of arguments");
  }
  return isInAnyRole(["tzAdmin", "facilityAdmin"]) && inboxAccess;
}

function InsuranceTypes() {
  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  const {isInAnyRole, inboxAccess, userFacilityId} = useJwt();

  //---------------------------------------------------------------------------
  // Load data from the API
  //
  //---------------------------------------------------------------------------
  const [tableLoading, setTableLoading] = React.useState(true);
  const [insuranceTypes, setInsuranceTypes] = React.useState([]);
  const [facilities, setFacilities] = React.useState({});
  const getInsuranceTypes = React.useCallback(async () => {
    const urlQuery = {id: {$or: [{$like: `${userFacilityId}_%`}, {$eq: userFacilityId}]}};
    if (isInAnyRole(["tzAdmin"])) {
      delete urlQuery.id;
    }

    try {
      const [{data: insuranceTypesResponse}, {data: facilitiesResponse}] = await Promise.all([
        axios({
          method: "get",
          url: `/facilities/${userFacilityId}/insuranceTypes`,
        }),
        axios({
          method: "get",
          url: "/facilities",
          params: urlQuery,
        }),
      ]);

      setInsuranceTypes(insuranceTypesResponse);

      // Marshall facilities array to an object
      const facilitiesObject = keyBy(facilitiesResponse, "id");
      setFacilities(facilitiesObject);
    } catch (err) {
      setError(err.message);
    }
    setTableLoading(false);
  }, [isInAnyRole, userFacilityId]);

  useInterval(getInsuranceTypes, DATA_REFRESH_INTERVAL_MS, tableLoading);

  //---------------------------------------------------------------------------
  // Search and sort
  //---------------------------------------------------------------------------
  // Update the search helper system
  const {search, setSearch, setSearchHelper, setSearchFields} = React.useContext(SearchContext);
  React.useEffect(() => {
    setSearchHelper(searchHelper);
    setSearchFields(searchFields);
    return () => {
      setSearchHelper("");
      setSearchFields("");
    };
  }, [setSearchHelper, setSearchFields]);

  // Refresh the page data if the user navigates to the same page again (e.g. via side-nav)
  const location = useLocation();
  React.useEffect(() => {
    return () => {
      // Reset the search bar every time we navigate to a new page
      setSearch("");
      setTableLoading(true);
    };
  }, [location, setSearch]);

  const filteredTypes = useFilter(insuranceTypes, search, searchFields);
  const [sortedTypes, handleSortSelection, sort] = useSort(filteredTypes, {defaultSort});

  //---------------------------------------------------------------------------
  // Pagination support
  //---------------------------------------------------------------------------
  const [page, setPage] = React.useState(0);
  const pageTypes = React.useMemo(
    () => sortedTypes.slice(page * pageSize, (page + 1) * pageSize),
    [page, sortedTypes]
  );
  React.useEffect(() => setPage(0), [search]);

  //--------------------------------------------------------------------------
  // Role Limiting
  //--------------------------------------------------------------------------
  if (!allowInsuranceTypes(isInAnyRole, inboxAccess)) {
    return <Page404 />;
  }

  return (
    <>
      <Helmet>
        <title>Insurance Types - BitRhythm Admin</title>
      </Helmet>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
      {
        //---------------------------------------------------------------------------
        // Display a loading spinner if we're still waiting on the API
        //---------------------------------------------------------------------------

        tableLoading && <TableLoading />
      }
      {
        //---------------------------------------------------------------------------
        // Display a message if there are no matching results, instead of the table
        //---------------------------------------------------------------------------
        !tableLoading && sortedTypes.length === 0 && (
          <>
            <NoResults />
            {!facilities[userFacilityId]?.billingFacility?.name && (
              <InsuranceTypesForm insuranceTypes={insuranceTypes} setTableReload={setTableLoading} />
            )}
          </>
        )
      }
      {
        //---------------------------------------------------------------------------
        // Render the table and the FAB
        //---------------------------------------------------------------------------
        !tableLoading && sortedTypes.length > 0 && (
          <>
            <InsuranceTypesHeader sort={sort} setSort={handleSortSelection} />
            {pageTypes.map((type) => (
              <InsuranceTypesRow
                key={type.id}
                insuranceType={type}
                insuranceTypes={insuranceTypes}
                setTableReload={setTableLoading}
              />
            ))}
            <Pagination pageSize={pageSize} page={page} setPage={setPage} count={sortedTypes.length} />
            {!facilities[userFacilityId]?.billingFacility?.name && (
              <InsuranceTypesForm insuranceTypes={insuranceTypes} setTableReload={setTableLoading} />
            )}
          </>
        )
      }
    </>
  );
}

export default InsuranceTypes;
