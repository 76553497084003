/* eslint-env browser */
import React from "react";
import {Helmet} from "react-helmet-async";
import {useLocation} from "react-router-dom";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useFilter, useInterval, useSort} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import SearchContext from "../../components/contexts/SearchContext.jsx";
import useJwt from "../../components/contexts/useJwt.jsx";
import useEnvironmentVariables from "../../components/hooks/useEnvironmentVariables.jsx";
import Alert from "../../components/primitives/Alert.jsx";
import NoResults from "../../components/primitives/NoResults.jsx";
import Pagination from "../../components/primitives/Pagination.jsx";
import TableLoading from "../../components/primitives/TableLoading.jsx";
import Page404 from "../Page404.jsx";

//---------------------------------------------------------------------------
// Lazy-load page-specific components
//---------------------------------------------------------------------------
const DeviceVariantHeader = React.lazy(
  () => import(/* webpackPrefetch: true */ "./DeviceVariantsHeader.jsx")
);
const DeviceVariantRow = React.lazy(() => import(/* webpackPrefetch: true */ "./DeviceVariantsRow.jsx"));
const DeviceVariantsForm = React.lazy(
  () => import(/* webpackPrefetch: true */ "../../dialogs/DeviceVariantsForm.jsx")
);

// Unless we can get socket.io or long polling working, fetching the data
// every 15 seconds should keep things from getting "stale"
const DATA_REFRESH_INTERVAL_MS = 15000;

const communicationPlans = [
  {id: "none", name: "None"},
  {id: "TZ Medical - AT&T LTE/SMS - 3579", name: "LTE North America"},
  {id: "TZ Medical - AT&T LTE/SMS - GRP A", name: "LTE International Group A Countries"},
  {id: "TZ Medical - AT&T SMS - EIDC", name: "3G North America"},
];
const ratePlans = [
  {id: "none", name: "None"},
  {id: "TZ Medical - 0KB Intl Plan", name: "LTE Data Plan"},
  {id: "TZ Medical - 0kB Plan", name: "3G Data Plan"},
];
const getNameById = (objectArray, idString) => {
  const foundTypes = objectArray.filter((object) => object.id === idString);
  return foundTypes[0]?.name || "";
};

//---------------------------------------------------------------------------
// Search configuration
//---------------------------------------------------------------------------
const searchFields = {
  gtin: "gtin",
  description: "description",
  pcb: "pcbPartNumber",
  firmware: "firmwareVersion",
  graphics: "graphicsVersion",
  type: "deviceTypeName",
  communication: "communicationPlanName",
  rate: "ratePlanName",
  is: {
    wireless: (object) => object.capableOfWirelessStudies,
  },
};
const searchHelper = [
  {label: "Has the words", keyword: "+", variant: "global"},
  {label: "Doesn't have", keyword: "-", variant: "global"},
  {label: "Description", keyword: "description", variant: "negatable"},
  {label: "GTIN", keyword: "gtin", variant: "negatable"},
  {label: "Firmware Version", keyword: "firmware", variant: "relative"},
  {label: "Graphics Version", keyword: "graphics", variant: "relative"},
  {label: "PCB Part Number", keyword: "pcb", variant: "negatable"},
  {label: "Wireless Capable", keyword: "is:wireless", variant: "toggle"},
];

const defaultSort = {
  field: "gtin",
  reverse: false,
};
const pageSize = 50;

//--------------------------------------------------------------------------
export function allowDeviceVariants(isInAnyRole) {
  return isInAnyRole(["tzAdmin", "warehouse"]);
}

function DeviceVariants() {
  //---------------------------------------------------------------------------
  // Global variables
  //---------------------------------------------------------------------------
  const {features} = useEnvironmentVariables();

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  const {isInAnyRole} = useJwt();

  //---------------------------------------------------------------------------
  // Load data from the API
  //
  // Device Types (e.g.Trident 40L, etc.)
  // Device Variants by GTIN (e.g. 00811331010251, etc.)
  //---------------------------------------------------------------------------
  const [tableLoading, setTableLoading] = React.useState(true);
  const [deviceTypes, setDeviceTypes] = React.useState([{id: "0"}]);
  const [deviceVariants, setDeviceVariants] = React.useState([]);
  const getDeviceVariants = React.useCallback(async () => {
    try {
      const [{data: typeResponse}, {data: variantResponse}] = await Promise.all([
        axios({
          method: "get",
          url: "/deviceTypes",
        }),
        axios({
          method: "get",
          url: "/deviceVariants",
        }),
      ]);

      //---------------------------------------------------------------------------
      // Join the Communication Plan and Rate Plan names onto the
      // Device Variants Array so we don't calculate it 3+ times.
      // Place deviceTypeName onto deviceVariant object for search/sort
      //---------------------------------------------------------------------------
      const joinedDeviceVariants = variantResponse.map((variant) => {
        return {
          ...variant,
          communicationPlanName: getNameById(communicationPlans, variant.communicationPlan || "none"),
          ratePlanName: getNameById(ratePlans, variant.ratePlan || "none"),
          deviceTypeName: variant.deviceType.name,
        };
      });

      setDeviceTypes(typeResponse);
      setDeviceVariants(joinedDeviceVariants);
    } catch (err) {
      setError(err.message);
    }
    setTableLoading(false);
  }, []);

  useInterval(getDeviceVariants, DATA_REFRESH_INTERVAL_MS, tableLoading);

  //---------------------------------------------------------------------------
  // Search and sort
  //---------------------------------------------------------------------------
  // Update the search helper system
  const {search, setSearch, setSearchHelper, setSearchFields} = React.useContext(SearchContext);
  React.useEffect(() => {
    setSearchHelper(searchHelper);
    setSearchFields(searchFields);
    return () => {
      setSearchHelper("");
      setSearchFields("");
    };
  }, [setSearchHelper, setSearchFields]);

  // Refresh the page data if the user navigates to the same page again (e.g. via side-nav)
  const location = useLocation();
  React.useEffect(() => {
    return () => {
      // Reset the search bar every time we navigate to a new page
      setSearch("");
      setTableLoading(true);
    };
  }, [location, setSearch]);

  const filteredVariants = useFilter(deviceVariants, search, searchFields);
  const [sortedVariants, handleSortSelection, sort] = useSort(filteredVariants, {defaultSort});

  //---------------------------------------------------------------------------
  // Pagination support
  //---------------------------------------------------------------------------
  const [page, setPage] = React.useState(0);
  const pageVariants = React.useMemo(
    () => sortedVariants.slice(page * pageSize, (page + 1) * pageSize),
    [page, sortedVariants]
  );
  React.useEffect(() => setPage(0), [search]);

  //--------------------------------------------------------------------------
  // Role Limiting
  //--------------------------------------------------------------------------
  if (!allowDeviceVariants(isInAnyRole)) {
    return <Page404 />;
  }

  return (
    <>
      <Helmet>
        <title>Device Variants - BitRhythm Admin</title>
      </Helmet>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
      {
        //---------------------------------------------------------------------------
        // Display a loading spinner if we're still waiting on the API
        //---------------------------------------------------------------------------
        tableLoading && <TableLoading />
      }
      {
        //---------------------------------------------------------------------------
        // Display a message if there are no matching results, instead of the table
        //---------------------------------------------------------------------------
        !tableLoading && sortedVariants.length === 0 && (
          <>
            <NoResults />
            {isInAnyRole(["tzAdmin"]) && (
              <DeviceVariantsForm
                deviceTypes={deviceTypes}
                communicationPlans={communicationPlans}
                ratePlans={ratePlans}
                features={features}
                deviceVariants={deviceVariants}
                setTableReload={setTableLoading}
              />
            )}
          </>
        )
      }
      {
        //---------------------------------------------------------------------------
        // Render the table and the FAB
        //---------------------------------------------------------------------------
        !tableLoading && sortedVariants.length > 0 && (
          <>
            <DeviceVariantHeader sort={sort} setSort={handleSortSelection} features={features} />
            {pageVariants.map((variant) => (
              <DeviceVariantRow
                key={variant.gtin}
                deviceVariant={variant}
                deviceTypes={deviceTypes}
                communicationPlans={communicationPlans}
                ratePlans={ratePlans}
                features={features}
                alwaysOpen={pageVariants.length === 1}
                setTableReload={setTableLoading}
              />
            ))}
            <Pagination pageSize={pageSize} page={page} setPage={setPage} count={sortedVariants.length} />

            {isInAnyRole(["tzAdmin"]) && (
              <DeviceVariantsForm
                deviceTypes={deviceTypes}
                communicationPlans={communicationPlans}
                ratePlans={ratePlans}
                features={features}
                deviceVariants={deviceVariants}
                setTableReload={setTableLoading}
              />
            )}
          </>
        )
      }
    </>
  );
}

export default DeviceVariants;
