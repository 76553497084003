/* eslint-env browser */
import React from "react";
import {Helmet} from "react-helmet-async";
import {useLocation} from "react-router-dom";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useFilter, useInterval, useSort} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import SearchContext from "../../components/contexts/SearchContext.jsx";
import useJwt from "../../components/contexts/useJwt.jsx";
import useEnvironmentVariables from "../../components/hooks/useEnvironmentVariables.jsx";
import Alert from "../../components/primitives/Alert.jsx";
import NoResults from "../../components/primitives/NoResults.jsx";
import Pagination from "../../components/primitives/Pagination.jsx";
import TableLoading from "../../components/primitives/TableLoading.jsx";
import Page404 from "../Page404.jsx";

//---------------------------------------------------------------------------
// Lazy-load page-specific components
//---------------------------------------------------------------------------
const DeviceConfigurationHeader = React.lazy(
  () => import(/* webpackPrefetch: true */ "./DeviceConfigurationsHeader.jsx")
);
const DeviceConfigurationRow = React.lazy(
  () => import(/* webpackPrefetch: true */ "./DeviceConfigurationsRow.jsx")
);
const DeviceConfigurationsForm = React.lazy(
  () => import(/* webpackPrefetch: true */ "../../dialogs/DeviceConfigurationsForm.jsx")
);

// Unless we can get socket.io or long polling working, fetching the data
// every 15 seconds should keep things from getting "stale"
const DATA_REFRESH_INTERVAL_MS = 15000;

const booleanOptions = [
  {id: "true", name: "Yes"},
  {id: "false", name: "No"},
];
const suppressRhythmOptions = [
  {id: "n", name: "Normal Sinus Rhythm"},
  {id: "b", name: "Bradycardia"},
  {id: "t", name: "Tachycardia"},
  {id: "a", name: "Atrial Fibrillation"},
  {id: "p", name: "Cardiac Pause"},
  {id: "U", name: "Unreadable ECG data"},
];

//---------------------------------------------------------------------------
// Export search configuration for use in navbar
//---------------------------------------------------------------------------
const searchFields = {
  name: "name",
  // NOTE: I can't think of a good reason to ever do this, but here's an example anyways...
  // silly: (object) => object.name,
  banner: ["topBanner", "bottomBanner"],
  is: {
    // NOTE: `value` will always equal the object key for this sort of callback
    default: (object) => object.isDefault,
  },
  has: {
    diary: (object) => object.activityDiaryEntries > 0 || object.symptomDiaryEntries > 0,
  },
};
const searchHelper = [
  {label: "Has the words", keyword: "+", variant: "global"},
  {label: "Doesn't have", keyword: "-", variant: "global"},
  {label: "Configuration name", keyword: "name", variant: "negatable"},
  {label: "Default Configurations", keyword: "is:default", variant: "toggle"},
  {label: "Has diary settings", keyword: "has:diary", variant: "toggle"},
];

const defaultSort = {
  field: "name",
  reverse: false,
};
const pageSize = 50;

//--------------------------------------------------------------------------
export function allowDeviceConfigurations(isInAnyRole, inboxAccess) {
  if (arguments.length !== 2) {
    throw new Error("Wrong number of arguments");
  }
  return isInAnyRole(["tzAdmin", "facilityAdmin"]) && inboxAccess;
}

function DeviceConfigurations() {
  //---------------------------------------------------------------------------
  // Global variables
  //---------------------------------------------------------------------------
  const {features} = useEnvironmentVariables();

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  const {inboxAccess, isInAnyRole, userFacilityId} = useJwt();

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  const [tableLoading, setTableLoading] = React.useState(true);
  const [deviceConfigurations, setDeviceConfigurations] = React.useState([]);
  const getDeviceConfigurations = React.useCallback(async () => {
    try {
      const {data: deviceConfigurationResponse} = await axios({
        method: "get",
        url: "facilityDeviceConfigurations/h3r",
        params: {facilityId: userFacilityId},
      });

      setDeviceConfigurations(deviceConfigurationResponse);
    } catch (err) {
      setError(err.message);
    }
    setTableLoading(false);
  }, [userFacilityId]);

  useInterval(getDeviceConfigurations, DATA_REFRESH_INTERVAL_MS, tableLoading);

  //---------------------------------------------------------------------------
  // Search and sort
  //---------------------------------------------------------------------------
  // Update the search helper system
  const {search, setSearch, setSearchHelper, setSearchFields} = React.useContext(SearchContext);
  React.useEffect(() => {
    setSearchHelper(searchHelper);
    setSearchFields(searchFields);
    return () => {
      setSearchHelper("");
      setSearchFields("");
    };
  }, [setSearchHelper, setSearchFields]);

  // Refresh the page data if the user navigates to the same page again (e.g. via side-nav)
  const location = useLocation();
  React.useEffect(() => {
    return () => {
      // Reset the search bar every time we navigate to a new page
      setSearch("");
      setTableLoading(true);
    };
  }, [location, setSearch]);

  const filteredDeviceConfigurations = useFilter(deviceConfigurations, search, searchFields);
  const [sortedDeviceConfigurations, handleSortSelection, sort] = useSort(filteredDeviceConfigurations, {
    defaultSort,
  });

  //---------------------------------------------------------------------------
  // Pagination support
  //---------------------------------------------------------------------------
  const [page, setPage] = React.useState(0);
  const pageDeviceConfigurations = React.useMemo(
    () => sortedDeviceConfigurations.slice(page * pageSize, (page + 1) * pageSize),
    [page, sortedDeviceConfigurations]
  );
  React.useEffect(() => setPage(0), [search]);

  //--------------------------------------------------------------------------
  // Role Limiting
  //--------------------------------------------------------------------------
  if (!allowDeviceConfigurations(isInAnyRole, inboxAccess)) {
    return <Page404 />;
  }

  return (
    <>
      <Helmet>
        <title>Device Configurations - BitRhythm Admin</title>
      </Helmet>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
      {
        //---------------------------------------------------------------------------
        // Display a loading spinner if we're still waiting on the API
        //---------------------------------------------------------------------------
        tableLoading && <TableLoading />
      }
      {
        //---------------------------------------------------------------------------
        // Display a message if there are no matching results, instead of the table
        //---------------------------------------------------------------------------
        !tableLoading && sortedDeviceConfigurations.length === 0 && (
          <>
            <NoResults />
            <DeviceConfigurationsForm
              deviceConfigurations={deviceConfigurations}
              booleanOptions={booleanOptions}
              suppressRhythmOptions={suppressRhythmOptions}
              setTableReload={setTableLoading}
            />
          </>
        )
      }
      {
        //---------------------------------------------------------------------------
        // Render the table and the FAB
        //---------------------------------------------------------------------------
        !tableLoading && sortedDeviceConfigurations.length > 0 && (
          <>
            <DeviceConfigurationHeader sort={sort} setSort={handleSortSelection} features={features} />
            {pageDeviceConfigurations.map((deviceConfig) => (
              <DeviceConfigurationRow
                key={deviceConfig.id}
                deviceConfiguration={deviceConfig}
                deviceConfigurations={deviceConfigurations}
                booleanOptions={booleanOptions}
                suppressRhythmOptions={suppressRhythmOptions}
                features={features}
                alwaysOpen={pageDeviceConfigurations.length === 1}
                setTableReload={setTableLoading}
              />
            ))}
            <Pagination
              pageSize={pageSize}
              page={page}
              setPage={setPage}
              count={sortedDeviceConfigurations.length}
            />
            <DeviceConfigurationsForm
              deviceConfigurations={deviceConfigurations}
              booleanOptions={booleanOptions}
              suppressRhythmOptions={suppressRhythmOptions}
              setTableReload={setTableLoading}
            />
          </>
        )
      }
    </>
  );
}

export default DeviceConfigurations;
