/* eslint-env browser */
import React from "react";
import {Helmet} from "react-helmet-async";
import {useLocation} from "react-router-dom";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useFilter, useInterval, useSort} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import SearchContext from "../../components/contexts/SearchContext.jsx";
import useJwt from "../../components/contexts/useJwt.jsx";
import Alert from "../../components/primitives/Alert.jsx";
import NoResults from "../../components/primitives/NoResults.jsx";
import Pagination from "../../components/primitives/Pagination.jsx";
import TableLoading from "../../components/primitives/TableLoading.jsx";
import Page404 from "../Page404.jsx";

//---------------------------------------------------------------------------
// Lazy-load page-specific components
//---------------------------------------------------------------------------
const DeviceTypesForm = React.lazy(
  () => import(/* webpackPrefetch: true */ "../../dialogs/DeviceTypesForm.jsx")
);
const DeviceTypesHeader = React.lazy(() => import(/* webpackPrefetch: true */ "./DeviceTypesHeader.jsx"));
const DeviceTypesRow = React.lazy(() => import(/* webpackPrefetch: true */ "./DeviceTypesRow.jsx"));

// Unless we can get socket.io or long polling working, fetching the data
// every 15 seconds should keep things from getting "stale"
const DATA_REFRESH_INTERVAL_MS = 15000;

//---------------------------------------------------------------------------
// Export search configuration for use in navbar
//---------------------------------------------------------------------------
const searchFields = {
  name: "name",
  version: "minFirmwareVersion",
  firmware: "minFirmwareVersion",
  id: ["firmwareId", "graphicsId"],
};
const searchHelper = [
  {label: "Has the words", keyword: "+", variant: "global"},
  {label: "Doesn't have", keyword: "-", variant: "global"},
  {label: "Type name", keyword: "name", variant: "negatable"},
  {label: "Minimum Firmware", keyword: "firmware", variant: "relative"},
  {label: "ID", keyword: "id", variant: "negatable"},
];

const defaultSort = {
  field: "name",
  reverse: false,
};
const pageSize = 50;

//--------------------------------------------------------------------------
export function allowDeviceTypes(isInAnyRole) {
  return isInAnyRole(["tzAdmin"]);
}

function DeviceTypes() {
  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  const {isInAnyRole} = useJwt();

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  const [tableLoading, setTableLoading] = React.useState(true);
  const [deviceTypes, setDeviceTypes] = React.useState([]);
  const [firmwareReleaseNotes, setFirmwareReleaseNotes] = React.useState([]);
  const [graphicsReleaseNotes, setGraphicsReleaseNotes] = React.useState([]);
  const getData = React.useCallback(async () => {
    try {
      const [{data: deviceTypesResponse}, {data: firmwareResponse}, {data: graphicsResponse}] =
        await Promise.all([
          axios({
            method: "get",
            url: "/deviceTypes",
          }),
          axios({
            method: "get",
            url: "/firmware/storage/releaseNotes",
          }),
          axios({
            method: "get",
            url: "/graphics/storage/releaseNotes",
          }),
        ]);

      // Need to add an artificial ID to each release note for the dropdown
      const formattedFirmware = firmwareResponse.map((releaseNote, index) => ({
        id: index,
        name: releaseNote,
      }));
      const formattedGraphics = graphicsResponse.map((releaseNote, index) => ({
        id: index,
        name: releaseNote,
      }));

      setDeviceTypes(deviceTypesResponse);
      setFirmwareReleaseNotes(formattedFirmware);
      setGraphicsReleaseNotes(formattedGraphics);
    } catch (err) {
      setError(err.message);
    }
    setTableLoading(false);
  }, [setDeviceTypes, setError, setTableLoading]);

  useInterval(getData, DATA_REFRESH_INTERVAL_MS, tableLoading);

  //---------------------------------------------------------------------------
  // Search and sort
  //---------------------------------------------------------------------------
  // Update the search helper system
  const {search, setSearch, setSearchHelper, setSearchFields} = React.useContext(SearchContext);
  React.useEffect(() => {
    setSearchHelper(searchHelper);
    setSearchFields(searchFields);
    return () => {
      setSearchHelper("");
      setSearchFields("");
    };
  }, [setSearchHelper, setSearchFields]);

  // Refresh the page data if the user navigates to the same page again (e.g. via side-nav)
  const location = useLocation();
  React.useEffect(() => {
    return () => {
      // Reset the search bar every time we navigate to a new page
      setSearch("");
      setTableLoading(true);
    };
  }, [location, setSearch]);

  const filteredDeviceTypes = useFilter(deviceTypes, search, searchFields);
  const [sortedDeviceTypes, handleSortSelection, sort] = useSort(filteredDeviceTypes, {defaultSort});

  //---------------------------------------------------------------------------
  // Pagination support
  //---------------------------------------------------------------------------
  const [page, setPage] = React.useState(0);
  const pageDeviceTypes = React.useMemo(
    () => sortedDeviceTypes.slice(page * pageSize, (page + 1) * pageSize),
    [page, sortedDeviceTypes]
  );
  React.useEffect(() => setPage(0), [search]);

  //--------------------------------------------------------------------------
  // Role Limiting
  //--------------------------------------------------------------------------
  if (!allowDeviceTypes(isInAnyRole)) {
    return <Page404 />;
  }

  return (
    <>
      <Helmet>
        <title>Device Types - BitRhythm Admin</title>
      </Helmet>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
      {
        //---------------------------------------------------------------------------
        // Display a loading spinner if we're still waiting on the API
        //---------------------------------------------------------------------------
        tableLoading && <TableLoading />
      }
      {
        //---------------------------------------------------------------------------
        // Display a message if there are no matching results, instead of the table
        //---------------------------------------------------------------------------
        !tableLoading && sortedDeviceTypes.length === 0 && (
          <>
            <NoResults />
            <DeviceTypesForm
              deviceTypes={deviceTypes}
              setTableReload={setTableLoading}
              firmwareReleaseNotes={firmwareReleaseNotes}
              graphicsReleaseNotes={graphicsReleaseNotes}
            />
          </>
        )
      }
      {
        //---------------------------------------------------------------------------
        // Render the table and the FAB
        //---------------------------------------------------------------------------
        !tableLoading && sortedDeviceTypes.length > 0 && (
          <>
            <DeviceTypesHeader sort={sort} setSort={handleSortSelection} />
            {pageDeviceTypes.map((deviceType) => (
              <DeviceTypesRow
                key={deviceType.name}
                deviceType={deviceType}
                deviceTypes={deviceTypes}
                setError={setError}
                alwaysOpen={pageDeviceTypes.length === 1}
                setTableReload={setTableLoading}
                firmwareReleaseNotes={firmwareReleaseNotes}
                graphicsReleaseNotes={graphicsReleaseNotes}
              />
            ))}
            <Pagination pageSize={pageSize} page={page} setPage={setPage} count={sortedDeviceTypes.length} />
            <DeviceTypesForm
              deviceTypes={deviceTypes}
              setTableReload={setTableLoading}
              firmwareReleaseNotes={firmwareReleaseNotes}
              graphicsReleaseNotes={graphicsReleaseNotes}
            />
          </>
        )
      }
    </>
  );
}

export default DeviceTypes;
