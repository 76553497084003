/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Close from "@mui/icons-material/Close";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// Page-specific components
//---------------------------------------------------------------------------
import DeviceActionsForm from "../../dialogs/DevicesForm/DeviceActionsForm.jsx";
import DeviceOperationalStateForm from "../../dialogs/DevicesForm/DeviceOperationalStateForm.jsx";

function DeviceActionsTopBar({
  // Props
  devices,
  checkedRows,
  setCheckedRows,
  setTableReload,
}) {
  const handleClose = React.useCallback(() => {
    setCheckedRows([]);
  }, [setCheckedRows]);
  //---------------------------------------------------------------------------
  // Render the device actions top bar
  //---------------------------------------------------------------------------
  return (
    <Grid container sx={{alignItems: "center", width: "100vw"}}>
      <Grid size="auto">
        <Box sx={{justifyContent: "flex-start", display: "flex"}}>
          <Button sx={{color: "white"}} id="cancelButton" onClick={handleClose}>
            <Close />
          </Button>
        </Box>
      </Grid>
      <Grid size="grow">
        <Typography align="center" sx={{color: "white", fontSize: "1.125rem"}}>
          {checkedRows.length} Selected
        </Typography>
      </Grid>
      <Grid size="auto">
        <Box sx={{justifyContent: "flex-end", display: "flex"}}>
          <DeviceOperationalStateForm
            devices={devices}
            checkedRows={checkedRows}
            multipleDevices
            setCheckedRows={setCheckedRows}
            setTableReload={setTableReload}
          />
          <DeviceActionsForm
            devices={devices}
            checkedRows={checkedRows}
            multipleDevices
            setCheckedRows={setCheckedRows}
            setTableReload={setTableReload}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

DeviceActionsTopBar.propTypes = {
  devices: PropTypes.array.isRequired,
  checkedRows: PropTypes.array.isRequired,
  setCheckedRows: PropTypes.func.isRequired,
  setTableReload: PropTypes.func.isRequired,
};

export default DeviceActionsTopBar;
