/* eslint-env browser */
import React from "react";
import {Helmet} from "react-helmet-async";
import {useLocation} from "react-router-dom";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useFilter, useInterval, useSort} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import SearchContext from "../../components/contexts/SearchContext.jsx";
import useJwt from "../../components/contexts/useJwt.jsx";
import Alert from "../../components/primitives/Alert.jsx";
import NoResults from "../../components/primitives/NoResults.jsx";
import Pagination from "../../components/primitives/Pagination.jsx";
import TableLoading from "../../components/primitives/TableLoading.jsx";
import Page404 from "../Page404.jsx";

//---------------------------------------------------------------------------
// Lazy-load page-specific components
//---------------------------------------------------------------------------
const MessageOptionsForm = React.lazy(
  () => import(/* webpackPrefetch: true */ "../../dialogs/MessageOptionsForm.jsx")
);
const MessageOptionsHeader = React.lazy(
  () => import(/* webpackPrefetch: true */ "./MessageOptionsHeader.jsx")
);
const MessageOptionsRow = React.lazy(() => import(/* webpackPrefetch: true */ "./MessageOptionsRow.jsx"));

// Unless we can get socket.io or long polling working, fetching the data
// every 15 seconds should keep things from getting "stale"
const DATA_REFRESH_INTERVAL_MS = 15000;

//---------------------------------------------------------------------------
// Export search configuration for use in navbar
//---------------------------------------------------------------------------
const searchFields = {
  message: "message",
};
const searchHelper = [
  {label: "Has the words", keyword: "+", variant: "global"},
  {label: "Doesn't have", keyword: "-", variant: "global"},
];

const defaultSort = {
  field: "message",
  reverse: false,
};
const pageSize = 50;

//--------------------------------------------------------------------------
export function allowMessageOptions(isInAnyRole, inboxAccess) {
  if (arguments.length !== 2) {
    throw new Error("Wrong number of arguments");
  }
  return isInAnyRole(["tzAdmin", "facilityAdmin"]) && inboxAccess;
}

function MessageOptions() {
  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  const {isInAnyRole, inboxAccess, userFacilityId} = useJwt();

  //---------------------------------------------------------------------------
  // Load data from the API
  //
  //---------------------------------------------------------------------------
  const [tableLoading, setTableLoading] = React.useState(true);
  const [messageOptions, setMessageOptions] = React.useState([]);
  const getMessageOptions = React.useCallback(async () => {
    try {
      const {data: messageOptionsResponse} = await axios({
        method: "get",
        url: "/deviceMessageOptions",
        params: {facilityId: userFacilityId},
      });

      setMessageOptions(messageOptionsResponse);
    } catch (err) {
      setError(err.message);
    }
    setTableLoading(false);
  }, [userFacilityId]);

  useInterval(getMessageOptions, DATA_REFRESH_INTERVAL_MS, tableLoading);

  //---------------------------------------------------------------------------
  // Search and sort
  //---------------------------------------------------------------------------
  // Update the search helper system
  const {search, setSearch, setSearchHelper, setSearchFields} = React.useContext(SearchContext);
  React.useEffect(() => {
    setSearchHelper(searchHelper);
    setSearchFields(searchFields);
    return () => {
      setSearchHelper("");
      setSearchFields("");
    };
  }, [setSearchHelper, setSearchFields]);

  // Refresh the page data if the user navigates to the same page again (e.g. via side-nav)
  const location = useLocation();
  React.useEffect(() => {
    return () => {
      // Reset the search bar every time we navigate to a new page
      setSearch("");
      setTableLoading(true);
    };
  }, [location, setSearch]);

  const filteredOptions = useFilter(messageOptions, search, searchFields);
  const [sortedOptions, handleSortSelection, sort] = useSort(filteredOptions, {defaultSort});

  //---------------------------------------------------------------------------
  // Pagination support
  //---------------------------------------------------------------------------
  const [page, setPage] = React.useState(0);
  const pageOptions = React.useMemo(
    () => sortedOptions.slice(page * pageSize, (page + 1) * pageSize),
    [page, sortedOptions]
  );
  React.useEffect(() => setPage(0), [search]);

  //--------------------------------------------------------------------------
  // Role Limiting
  //--------------------------------------------------------------------------
  if (!allowMessageOptions(isInAnyRole, inboxAccess)) {
    return <Page404 />;
  }

  return (
    <>
      <Helmet>
        <title>Message Options - BitRhythm Admin</title>
      </Helmet>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
      {
        //---------------------------------------------------------------------------
        // Display a loading spinner if we're still waiting on the API
        //---------------------------------------------------------------------------
        tableLoading && <TableLoading />
      }
      {
        //---------------------------------------------------------------------------
        // Display a message if there are no matching results, instead of the table
        //---------------------------------------------------------------------------
        !tableLoading && sortedOptions.length === 0 && (
          <>
            <NoResults />
            <MessageOptionsForm messageOptions={messageOptions} setTableReload={setTableLoading} />
          </>
        )
      }
      {
        //---------------------------------------------------------------------------
        // Render the table and the FAB
        //---------------------------------------------------------------------------
        !tableLoading && sortedOptions.length > 0 && (
          <>
            <MessageOptionsHeader sort={sort} setSort={handleSortSelection} />
            {pageOptions.map((option) => (
              <MessageOptionsRow
                key={option.id}
                messageOption={option}
                messageOptions={messageOptions}
                setTableReload={setTableLoading}
              />
            ))}
            <Pagination pageSize={pageSize} page={page} setPage={setPage} count={sortedOptions.length} />
            <MessageOptionsForm messageOptions={messageOptions} setTableReload={setTableLoading} />
          </>
        )
      }
    </>
  );
}

export default MessageOptions;
