/* eslint-env browser */

import React from "react";
import {createRoot} from "react-dom/client";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {BrowserRouter} from "react-router-dom";

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import AddProviders from "./components/contexts/AddProviders.jsx";
import AppRoutes from "./pages/App.jsx";

//---------------------------------------------------------------------------
// Render
//---------------------------------------------------------------------------
const root = createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <Helmet>
      <title>BitRhythm Admin</title>
    </Helmet>
    <BrowserRouter>
      <AddProviders>
        <AppRoutes />
      </AddProviders>
    </BrowserRouter>
  </HelmetProvider>
);
