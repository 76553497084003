/* eslint-env browser */
import React from "react";
import {Helmet} from "react-helmet-async";
import keyBy from "lodash/keyBy";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useInterval} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import useJwt from "../../components/contexts/useJwt.jsx";
import Alert from "../../components/primitives/Alert.jsx";
import TableLoading from "../../components/primitives/TableLoading.jsx";
import Page404 from "../Page404.jsx";

//---------------------------------------------------------------------------
// Lazy-load page-specific components
//---------------------------------------------------------------------------
const FirmwareReleasesDropdown = React.lazy(
  () => import(/* webpackPrefetch: true */ "./FirmwareReleasesDropdown.jsx")
);
const FirmwareReleasesTable = React.lazy(
  () => import(/* webpackPrefetch: true */ "./FirmwareReleasesTable.jsx")
);
const GraphicsReleasesTable = React.lazy(
  () => import(/* webpackPrefetch: true */ "./GraphicsReleasesTable.jsx")
);

// Unless we can get socket.io or long polling working, fetching the data
// every 15 seconds should keep things from getting "stale"
const DATA_REFRESH_INTERVAL_MS = 15000;

//--------------------------------------------------------------------------
export function allowFirmwareReleases(isInAnyRole) {
  return isInAnyRole(["tzAdmin", "warehouse", "facilityAdmin"]);
}

function FirmwareReleasesPage() {
  //---------------------------------------------------------------------------
  // Error management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // State management
  //---------------------------------------------------------------------------
  const [selectedDeviceType, setSelectedDeviceType] = React.useState({id: ""});
  const [deviceTypes, setDeviceTypes] = React.useState({});
  const [tableLoading, setTableLoading] = React.useState(true);

  //---------------------------------------------------------------------------
  // Helper Functions
  //---------------------------------------------------------------------------
  const {isInAnyRole} = useJwt();

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  const getReleases = React.useCallback(async () => {
    try {
      const {data: deviceTypesResponse} = await axios({
        url: "/deviceTypes",
        method: "get",
      });

      if (!Array.isArray(deviceTypesResponse)) {
        throw new Error("Device types must be of type array");
      }

      const formattedDeviceTypes = deviceTypesResponse.map((type) => ({
        ...type,
        ...(type.name && {type: type.name.replace(/\s/g, "-").toLowerCase()}),
      }));

      // Marshall deviceTypes array to an object
      const deviceTypeObject = keyBy(formattedDeviceTypes, "id");
      setDeviceTypes(deviceTypeObject);
    } catch (err) {
      setError(err.message);
    }

    setTableLoading(false);
  }, []);

  useInterval(getReleases, DATA_REFRESH_INTERVAL_MS, tableLoading);

  //--------------------------------------------------------------------------
  // Role Limiting
  //--------------------------------------------------------------------------
  if (!allowFirmwareReleases(isInAnyRole)) {
    return <Page404 />;
  }

  return (
    <>
      <Helmet>
        <title>Firmware Releases - BitRhythm Admin</title>
      </Helmet>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
      <Alert
        message={
          <>
            WARNING: New firmware releases may introduce changes that adversely affect device compatibility
            with other software platforms. <br />
            Please validate firmware compatibility prior to large-scale deployments of new firmware releases.
          </>
        }
        level="warning"
      />
      {
        //---------------------------------------------------------------------------
        // Display a loading spinner if we're still waiting on the API
        //---------------------------------------------------------------------------
        tableLoading && <TableLoading />
      }
      {
        //---------------------------------------------------------------------------
        // Render the firmware and graphics release tables
        //---------------------------------------------------------------------------
        !tableLoading && (
          <>
            {/* Device Type Select Dropdown */}
            <FirmwareReleasesDropdown
              selectedDeviceType={selectedDeviceType}
              deviceTypes={deviceTypes}
              setSelectedDeviceType={setSelectedDeviceType}
              setTableReload={setTableLoading}
            />
            {/* Firmware Release Table */}
            <FirmwareReleasesTable
              selectedDeviceType={selectedDeviceType}
              deviceTypes={deviceTypes}
              setError={setError}
            />
            {/* Graphics Release Table */}
            <GraphicsReleasesTable
              selectedDeviceType={selectedDeviceType}
              deviceTypes={deviceTypes}
              setError={setError}
            />
          </>
        )
      }
    </>
  );
}

export default FirmwareReleasesPage;
